<template>
	<PopupTwoCol name="menhir"
	text="<h3>Le menhir de la Vacherie</h3><br/><p>Ce géant de 4,85 mètres a été implanté à cet endroit entre 5 000 ou 6 000 avant J.-C. Il était surmonté d’une croix comme beaucoup de menhirs en Bretagne, le clergé voulant s’attribuer les bienfaits constatés à proximité des pierres. Cette croix a été ensuite renversée par la foudre. C’est un monument historique classé depuis 1889.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/Menhir_hdmd2o_c_scale_w_350.jpg 350w,
		img/popup/Menhir_hdmd2o_c_scale_w_724.jpg 724w,
		img/popup/Menhir_hdmd2o_c_scale_w_970.jpg 970w"
		data-src="img/popup/Menhir_hdmd2o_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
